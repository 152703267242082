import { ApolloClient, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

interface CreateApolloClientOptions {
  httpURI: string;
  launchParams: string;
}

export function createApolloClient(options: CreateApolloClientOptions) {
  const { httpURI, launchParams } = options;

  return new ApolloClient({
    uri: httpURI,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            organizationUnits: offsetLimitPagination(['input']),
          },
        },
      },
    }),
    headers: { 'x-launch-params': launchParams },
  });
}
